<template>
  <div>
    <section class="product-list-heading mb-5 shop-by-brand">
      <div class="container-lg text-center" v-if="!isLoaded">
        <font-awesome class="mt-5" icon="spinner" size="10x" spin />
        <br>
        <h3 class="mt-4 bold">Please wait for a moment</h3>
      </div>
      <div class="container-lg" v-else>
        <input class="mt-lg-4 my-2 form-control" type="text" placeholder="Search brand..." v-model="searchBrand" @input="viewGroup = null">
        <template v-if="viewGroup">
          <div class="clearfix">
            <fawesome-pro variant="far" icon="chevron-left" size="2x" class="float-left mt-2 cursor-pointer" @click.native="viewGroup = null" />
            <h1 class="my-lg-4 text-center">{{viewGroup.name}}</h1>
          </div>
          <div class="row row-cols-lg-3 no-gutters">
            <div class="col" v-for="(brand, j) in viewGroup.all_brands" :key="j">
              <BrandShowcase :brand="brand" :showChevron="true" />
            </div>
          </div>
        </template>
        <div v-else v-for="(group, i) in filteredBrand" :key="i">
          <div class="clearfix">
            <h1 class="my-lg-4 text-center">{{group.name}}</h1>
          </div>
          <VueSlickCarousel
            v-if="group.brands.length"
            class="slider-shop-brand"
            :class="{'d-lg-none' : group.brands.length <= 6}"
            v-bind="slickSettings"
          >
            <BrandShowcase v-for="(brand, j) in group.brands" :key="j" :brand="brand" />
          </VueSlickCarousel>
          <div class="row row-cols-3 d-none justify-content-center" :class="{'d-lg-flex' : group.brands.length <= 6}">
            <BrandShowcase v-for="(brand, j) in group.brands" :key="j" :brand="brand" />
          </div>
          <div class="text-center my-4" v-if="group.brands.length > 6">
            <button type="button" class="btn btn-wz-outline-gold" @click="getSingleGroup(group.id)">See More</button>
          </div>
          <hr v-if="i < groupedBrands.length - 1" />
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { toaster } from "@/_helpers";
import BrandShowcase from "@/components/mains/BrandShowcase";

import VueSlickCarousel from "vue-slick-carousel";

export default {
  name: "ShopByBrand",
  components: {
    BrandShowcase,
    VueSlickCarousel,
  },
  data() {
    return {
      groupedBrands: [],
      searchBrand: "",
      viewGroup: null,
      isLoaded: false,
      slickSettings: {
        centerMode: false,
        centerPadding: "0px",
        slidesToShow: 3,
        rows: 2,
        infinite: false,
        arrows: true,
        responsive: [
          {
            breakpoint: 992,
            settings: {
              arrows: false,
              slidesToShow: 2,
              rows: 1,
            },
          },
          {
            breakpoint: 481,
            settings: {
              centerMode: true,
              centerPadding: "30px",
              arrows: false,
              slidesToShow: 1,
              rows: 1,
            },
          },
        ],
      },
    }
  },
  computed: {
    filteredBrand: function() {
      const searchPattern = new RegExp(this.searchBrand, 'i');
      const filteredArr = this.groupedBrands.reduce((accum, ele) => {
        var obj = {};
        ele['brands'] && ele['brands'].forEach(e => {
          if (e['name'].match(searchPattern)) {
            obj['id'] = ele['id']
            obj['name'] = ele['name'];
            obj['brands'] ? (obj['brands'].push(e)) : (obj['brands'] = [], obj['brands'].push(e))
          }
        })
        if (Object.keys(obj).length > 0) accum.push(obj);
        return accum;
      }, [])

      return filteredArr
    }
  },
  mounted() {
    this.getGroupedBrands()
  },
  methods: {
    async getSingleGroup(id) {
      const index = this.groupedBrands.findIndex(el => el.id == id)
      const group = this.groupedBrands[index]
      if (group.all_brands.length == 0) {
        try {
          const res = await this.$api.cms.getSingleGroup(id)
          if (res.status === 200) {
            group.all_brands = res.data.data
            group.view_more = true
          }
          else toaster.make(res.data.message, "danger")
        } catch (e) {
          console.error(e);
        }
      }
      this.viewGroup = group
      window.scrollTo(0, 0)
    },
    async getGroupedBrands() {
      try {
        const res = await this.$api.cms.getGroupedBrands(10)
        if (res.status === 200) { 
          this.isLoaded = true
          this.groupedBrands = res.data.data.map(el => {
            el.all_brands = []
            el.view_more = false
            return el
          })
          setTimeout(() => {
            window.prerenderReady = true;
          }, 2000)
        }
        else {
          this.isLoaded = true
          toaster.make(res.data.message, "danger");
        }
      } catch (e) {
        this.isLoaded = true
        console.error(e);
      }
    }
  }
}
</script>
